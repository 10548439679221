.custom-select {
    position: relative;
    display: flex;
}

.select-header {
    padding: 9px 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background: #f0f0f5;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    outline: none;
    /* background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e"); */
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 16px;
}

.select-header:focus,
.select-header:hover {
    border-color: #0066cc;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.3);
}

.select-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-option {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.select-option:hover {
    background-color: #f0f0f5;
}

.flag {
    font-size: 24px;
}

.country-code {
    font-size: 12px;
}

/* Add scrollbar styling */
.select-options::-webkit-scrollbar {
    width: 8px;
}

.select-options::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.select-options::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.select-options::-webkit-scrollbar-thumb:hover {
    background: #555;
}