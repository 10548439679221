.add-user-icon {
    position: fixed;
    top: 5px;
    right: 25px;
    font-size: 15px;
    padding: 10px;
    border-radius: 100%;
    background-color: #f5f5f5;
    /* Make the icons larger */
    color: #000099;
    /* Match the "Send" button's active color */
    margin-left: 10px;
    /* Space between the icons */
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1000;
}

.add-user-icon:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.message-list-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* Match image border radius */
}

.spinner {
    width: 30px;
    height: 30px;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #000099;
    /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.image-message {
    padding: 1px 4px !important;
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9000;
}

.image-modal img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
    cursor: pointer;
}

/* Sender name style */
.sender-name {
    font-size: 12px;
    color: #8e8e93;
    font-weight: bold;
    flex-grow: 1;
    /* Allow sender name to take up available space */
    text-align: left;
    padding-right: 10px;
    /* Align to left for received messages */
}

.message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    width: 100%;
    position: relative;
}

.message-time {
    font-size: 0.8em;
    color: #777;
}

.message-list {
    flex: 1;
    /* Occupy most of the vertical space */
    padding: 10px;
    /* Add padding for messages */
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    /* Enable scrolling for messages */
}



/* Message Container */
.message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 70%;
    width: max-content
}

.message-container.sent {
    align-self: flex-end;
    /* Align sent messages to the right */
}

/* Individual Messages */
.message {
    margin-bottom: 12px;
    /* max-width: 70%; */
    /* width: max-content; */

    padding: 12px 18px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
}

.message.sent a {
    color: #000099 !important;
    text-decoration: none !important;
}

.message.sent a:hover {
    color: #0b121a !important;
    text-decoration: underline !important;
}

.message.received a {
    color: #c7c7c7 !important;
    text-decoration: none !important;
}

.message.received a:hover {
    color: #a3a3a3 !important;
    text-decoration: underline !important;
}

.message.sent {
    background-color: #CCC;
    /* Blue for sent messages */
    color: rgb(9, 9, 9);
    align-self: flex-end;
    border-bottom-right-radius: 5px;
    /* max-width: 70%; */
}

.message.received {
    background-color: #000099;
    /* Gray for received messages */
    color: white;
    align-self: flex-start;
    border-bottom-left-radius: 5px;
    /* max-width: 70%; */
}

.sent-time {
    margin-left: auto;
    /* Align time to the left for sent messages */
    text-align: left;
}

.received-time {
    margin-right: auto;
    /* Align time to the right for received messages */
    text-align: right;
}

/* Date Separator Line */
.date-separator {
    display: flex;
    align-items: center;
    margin: 20px 0;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: #8e8e93;
}

.date-separator::before,
.date-separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin: 0 10px;
}

.date-separator span {
    background-color: #f5f5f5;
    padding: 0 10px;
    border-radius: 10px;
    font-weight: bold;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
    max-width: 400px;
    margin: 20px;
    /* Match the width of the login/registration forms */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal h3 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
}

.country-select-modal {
    padding: 15px;
    padding-right: 30px;
    /* Match padding of other inputs */
    border: 1px solid #ddd;
    border-radius: 20px;
    /* Match rounded corners of other inputs */
    background: #f0f0f5;
    /* Match background color of other inputs */
    cursor: pointer;
    font-size: 18px;
    /* Match font size of other inputs */
    width: 33%;
    flex-shrink: 0;
    /* Keep width reasonable for the dropdown */
    outline: none;
    appearance: none;
    /* Remove default dropdown arrow */
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    /* Add custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 16px;
}

.phone-input-modal {
    width: 67%;
    /* Take up remaining space */
    flex-grow: 1;
    /* Allow growing if needed */
    padding: 15px;
    /* Match padding of other inputs */
    border: 1px solid #ddd;
    border-radius: 20px;
    /* Match rounded corners of other inputs */
    background: #f0f0f5;
    /* Match background color of other inputs */
    flex-grow: 1;
    font-size: 18px;
    /* Match font size of other inputs */
    outline: none;
}

.phone-input-modal:focus {
    border-color: #0066cc;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.3);
}

.phone-input-modal::placeholder {
    color: #8e8e93;
    /* Match placeholder color of other inputs */
}

/* Ensure the phone input container is centered and matches form styling */
.phone-input-container-modal {
    display: flex;
    gap: 8px;
    /* Space between country select and phone input */
    width: 100%;
    /* Take full width of modal */
    margin-bottom: 20px;
}

/* Style the error message to match the login/registration forms */
.modal .error-message {
    color: #b00020;
    background-color: #fce8e6;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    animation: fade-in 0.3s ease-in-out;
}

.modal-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.modal-actions button {
    padding: 12px 24px;
    /* Match the padding of join-button */
    font-size: 16px;
    border: none;
    border-radius: 20px;
    /* Match the rounded corners of join-button */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-actions button:first-child {
    background-color: #000099;
    color: white;
}

.modal-actions button:first-child:hover:enabled {
    background-color: #3333cc;
}

.modal-actions button:first-child:disabled {
    background-color: #b0b0b5;
    cursor: not-allowed;
}

.modal-actions button:last-child {
    background-color: #ddd;
    color: #333;
}

.modal-actions button:last-child:hover {
    background-color: #bbb;
}

/* Fade-in animation for error message */
@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}