.connection-indicator {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.connection-indicator.connected {
    background-color: #4CAF50;
}

.connection-indicator.disconnected {
    background-color: #9e9e9e;
}