/* Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    /* height: 100vh; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-container {
    display: flex;
    flex-direction: row;
    height: var(--app-height);
    width: 100vw;
    overflow: visible;
}

.side-drawer {
    flex-shrink: 0;
    height: 100vh;
    background-color: white;
    /* border-right: 1px solid #ddd; */
    overflow-y: auto;
    /* box-sizing: border-box; */
}

.resizer {
    width: 5px;
    cursor: col-resize;
    background-color: #ddd;
    height: 100vh;
}

.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    overflow: visible;
}




.message-input-container {
    flex-shrink: 0;
    /* Prevent shrinking */
    width: 100%;
    /* Full width */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    /* Anchor it to the bottom of the screen */
}

.input-main-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.message-input {
    flex: 1;
    /* Make the input expand to fill the available width */
    padding: 8px;
    min-width: 0;
    padding: 10px;
    border: 1px solid #ccc;
    /* Add a subtle border */
    border-radius: 10px;
    font-size: 14px;
    /* margin-right: 10px; */
    /* Add space between input and button */
    outline: none;
    box-sizing: border-box;
    /* background-color: #f0f0f5; */
    /* gap: 10px; */
    /* Ensure padding is included in the input's width */
}

.message-input.full-width {
    flex: 1;
    /* Take full available space */
    margin-right: 10px;
    /* Add spacing between input and button */
    min-width: 0;
    /* Prevent overflow */
    box-sizing: border-box;
    /* Include padding in the width */
}

.send-button.full-width {
    padding: 10px 20px;
    flex-shrink: 0;
    /* Prevent shrinking */
}

.message-input:disabled {
    background-color: #f3f3f3;
    color: #bbb;
}

.message-input::placeholder {
    color: #8e8e93;
}

.send-button {
    flex-shrink: 0;
    background-color: #000099;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.send-button:disabled {
    background-color: #bbb;
    cursor: not-allowed;
}

.send-button:hover:enabled {
    background-color: #0056b3;
}

/* Icon button for sending messages on mobile */
.send-icon-button {
    flex-shrink: 0;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #000099;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
}

/* Hover effect */
.send-icon-button:hover {
    background-color: #3333cc;
}

.send-icon-button:disabled {
    background-color: #bbb;
    cursor: not-allowed;
}

/* Client Setup Form */
.client-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    /* Changed from 90% */
    padding: 20px;
    box-sizing: border-box;
}

/* Form Styles */
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* max-width: 500px; */
}

/* Input Field */
input[type="text"] {
    /* width: 300px; */
    width: 100%;
    /* Make the input box larger */
    padding: 15px;
    /* Increase padding for a larger input field */
    font-size: 18px;
    /* Larger font size */
    border: 1px solid #ddd;
    border-radius: 20px;
    /* margin-bottom: 20px; */
    /* Add space between input and button */
    outline: none;
    background-color: #f0f0f5;
}

input[type="text"]::placeholder {
    color: #8e8e93;
}

/* Join Chat Button */
.join-button {
    background-color: #000099;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 12px 24px;
    /* Make the button slightly larger */
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    outline: none;
    transition: background-color 0.3s ease;
}

.join-button:hover {
    background-color: #3333cc;
}

.join-button:disabled {
    background-color: #b0b0b5;
    cursor: not-allowed;
}

.image-upload-icon {
    cursor: pointer;
    /* margin-right: 10px; */
    font-size: 18px;
    color: #000099;
    margin-right: 10px;
}

.message-image {
    max-width: 120px;
    max-height: 200px;
    border-radius: 14px;
    margin-top: 5px;
    cursor: pointer;
}

.input-preview-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    /* Space between the input and send button */
}

.image-preview {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Space between image and remove icon */
}

.preview-image {
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
    object-fit: cover;
}


.preview-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    /* Add space below the preview */
    box-sizing: border-box;
}

.remove-preview-icon {
    cursor: pointer;
    color: red;
    font-size: 18px;
}

.phone-input-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    /* max-width: 500px; */
    /* Match form max-width */
    padding: 0;
    box-sizing: border-box;
}

.country-select-container {

    /* Match font size of other inputs */
    width: 33%;
    flex-shrink: 0;
    /* Prevent shrinking */
}

.phone-input {
    padding: 15px;
    /* Match padding of other inputs */
    border: 1px solid #ddd;
    border-radius: 20px;
    /* Match rounded corners of other inputs */
    background: #f0f0f5;
    /* Match background color of other inputs */
    width: 67%;
    flex-grow: 1;
    font-size: 18px;
    /* Match font size of other inputs */
    outline: none;
}

.phone-input:focus {
    border-color: #0066cc;
    box-shadow: 0 0 5px rgba(0, 102, 204, 0.3);
}

.phone-input::placeholder {
    color: #8e8e93;
    /* Match placeholder color of other inputs */
}

.side-drawer-container {
    position: relative;
    z-index: 2000;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .side-drawer-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        /* Drawer width in mobile */
        height: 100vh;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
        /* Ensure it overlays other elements */
    }

    .side-drawer-container.open {
        transform: translateX(0);
    }

    .chat-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 500;
        /* Behind the drawer */
    }

    /* Toggle Drawer Icon */
    .toggle-drawer-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 999;
        font-size: 24px;
        color: #000099;
        cursor: pointer;
        padding: 10px;
        border-radius: 100%;
        background-color: #f5f5f5;
    }

    .toggle-drawer-icon:hover {
        color: #3333cc;
    }

    /* Error Message Styles */
    .error-message {
        color: #b00020;
        /* Accessible red color for text */
        background-color: #fce8e6;
        /* Light red background for emphasis */
        border: 1px solid #f5c6cb;
        /* Border to match the background theme */
        border-radius: 5px;
        /* Rounded corners */
        padding: 10px 15px;
        /* Add padding for better spacing */
        margin-top: 10px;
        /* Space above the error message */
        font-size: 14px;
        /* Readable font size */
        line-height: 1.5;
        /* Improved line height for readability */
        width: 100%;
        /* Full width to match the input */
        text-align: center;
        /* Center the text */
        box-sizing: border-box;
        /* Ensure padding is included in width */
        animation: fade-in 0.3s ease-in-out;
        /* Subtle fade-in animation */
    }

    /* Fade-in animation for error message */
    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    img {
        -webkit-touch-callout: none;
        /* Disable callout on long press for Safari */
        -webkit-user-select: none;
        /* Disable selection for touch devices */
        user-select: none;
        /* Disable selection for modern browsers */
    }


    /* .toggle-drawer-btn {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2000;
        padding: 10px 20px;
        background-color: #000099;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    } */
}

/* Google Sign-In Button Styles */
.google-login-button {
    display: flex;
    align-items: center;
    background-color: #000099;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    height: 40px;
    min-width: 240px;
}

.google-login-button .google-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 2px;
}

.google-login-button .google-icon-wrapper svg {
    width: 18px;
    height: 18px;
    color: #000099;
    /* Google blue color for the icon */
}

.google-login-button .button-text {
    flex-grow: 1;
    text-align: center;
    padding: 0 24px;
}

.google-login-button:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.google-login-button:active {
    background-color: #3367d6;
}

.oauth-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
}

.profile-modal {
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* Center the heading */
}

.profile-modal form {
    text-align: left;
    /* Reset text alignment for the form content */
}

.profile-modal h2 {
    margin-bottom: 20px;
    color: #333;
}

.profile-modal .phone-input-container {
    margin-bottom: 20px;
}

.profile-modal .error-message {
    color: #dc3545;
    margin-top: 5px;
    font-size: 14px;
}

.profile-modal-button {
    width: 100%;
    padding: 12px;
    background-color: #000099;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.profile-modal-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.input-container {
    margin-bottom: 20px;
    width: 100%;
}

.input-container h3 {
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
}

.text-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    /* Ensure padding doesn't affect width */
}

.text-input:focus {
    outline: none;
    border-color: #4285f4;
}

.oauth-signin-container h2 {
    text-align: center;
    margin-bottom: 120px;
    color: #333;
    font-size: 60px;
}

.google-login-button .spinner {
    width: 18px;
    height: 18px;
    border: 2px solid rgba(0, 0, 153, 0.2);
    border-top: 2px solid #000099;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.google-login-button:disabled {
    opacity: 0.8;
    cursor: default;
}

.google-login-button:disabled:hover {
    box-shadow: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.profile-modal-button.secondary {
    background-color: #6c757d;
}

.profile-modal-button.secondary:hover {
    background-color: #5a6268;
}

/* Add additional image security styles */
.message-image,
.fullscreen-image {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: auto;
    /* Allow clicks for fullscreen but block other interactions */
    -webkit-touch-callout: none;
    /* Disable iOS long-press menu */
}

/* Add a CSS rule to disable image download through Firefox's "View Image" option */
img {
    -moz-force-broken-image-icon: 0;
}

/* Optional: Add a visual indication that the image is protected */
.message-image::after,
.fullscreen-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 1;
}

/* Add a subtle visual cue on hover to indicate image is protected */
.message-image:hover::after,
.fullscreen-image:hover::after {
    background: rgba(255, 255, 255, 0.05);
}

/* Add styling for the fullscreen container to ensure it blocks all context menus */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    cursor: pointer;
    user-select: none;
}

/* Security tooltip styling */
.security-tooltip {
    animation: fadeInOut 2s ease-in-out;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 10000 !important;
    /* Ensure tooltip is above all other elements */
    pointer-events: none;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}