.side-drawer {
    width: 100%;
    /* Fixed width for the drawer */
    height: 100vh;
    /* Full viewport height */
    background-color: white;
    /* Background color */
    border-right: 1px solid #ddd;
    /* Separator line */
    overflow-y: auto;
    /* Allow scrolling if content overflows */
    padding: 0 10px;
    /* Add padding inside the drawer */
    box-sizing: border-box;
    /* Include padding and border in width/height */
}

.side-drawer h3 {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
}

.side-drawer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-drawer li {
    padding: 10px;
    border-bottom: 1px solid #f0f0f5;
    cursor: pointer;
    font-size: 16px;
}

.side-drawer li:hover {
    background-color: #f9f9f9;
}

.drawer-icon {
    font-size: 20px;
    /* Make the icons larger */
    color: #000099;
    /* Match the "Send" button's active color */
    margin-left: 10px;
    /* Space between the icons */
    cursor: pointer;
    transition: color 0.3s ease;
}

.drawer-icon:hover {
    color: #3333cc;
    /* Change color on hover */
}

.new-thread-button {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background-color: #000099;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.new-thread-button:hover {
    background-color: #3333cc;
}

/* .drawer-header {
    display: flex;
    justify-content: flex-end;
    /* gap: 10px; 
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #ddd;
}
*/

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #000099;
}

.icon-button:hover {
    color: #3333cc;
}

.modal-backdrop {
    position: fixed;
    /* Position relative to the viewport */
    top: 0;
    left: 0;
    width: 100vw;
    /* Full width of the screen */
    height: 100vh;
    /* Full height of the screen */
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it sits above other components */
}

.modal ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    max-height: 200px;
    /* Limit the height of the participants list */
    overflow-y: auto;
    /* Add scrollbar if content overflows vertically */
}

.modal li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    border-bottom: 1px solid #f0f0f5;
}

.modal li:last-child {
    border-bottom: none;
    /* Remove border for the last item */
}

.participant-name {
    flex-grow: 1;
    /* Ensures the name takes up available space */
}

.participant-phone {
    margin-left: 10px;
    /* Adds some space between the name and phone number */
    text-align: right;
    /* Aligns the phone number to the right */
}


.modal h3 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.modal-name-input {
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.modal-actions {
    display: flex;
    justify-content: center;
}

.modal-actions button {
    padding: 10px 20px;
    margin: 0px 5px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-actions button:first-child {
    background-color: #000099;
    color: white;
}

.modal-actions button:first-child:hover {
    background-color: #3333cc;
}

.modal-actions button:last-child {
    background-color: #ddd;
    color: #333;
}

.modal-actions button:last-child:hover {
    background-color: #bbb;
}

.thread-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.thread-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    /* border-bottom: 1px solid #ddd;
    transition: background-color 0.3s; */
}

.thread-item:hover {
    background-color: #f0f0f5;
}

.thread-item.selected {
    /* background-color: #000099;
    color: white; */
    font-weight: bold;
    background-color: #f0f0f0;
}

.thread-name {
    flex-grow: 1;
}

.participants-icon {
    cursor: pointer;
    color: #666;
    margin-left: 10px;
    /* Add some spacing between the thread name and the icon */
}

.participants-icon:hover {
    color: #000;
}

.copy-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #888;
    transition: color 0.2s ease;
}

.copy-icon:hover {
    color: #000;
}

/* Align profile icon to the left */
.drawer-header {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    background-color: white;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.profile-icon {
    margin-right: auto;
    cursor: pointer;
    font-size: 20px;
}

.profile-dropdown {
    position: absolute;
    top: 35px;
    left: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 120px;
}

.inbox-dropdown {
    position: absolute;
    top: 35px;
    right: -1px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    z-index: 2001;
    max-height: 200px;
}

.dropdown-item {
    padding: 10px;
    font-size: 14px;
    text-align: left;
    background-color: white;
    border: none;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.inbox-container {
    position: relative;
    display: inline-block;
}

.pending-invites-badge {
    position: absolute;
    top: -6px;
    right: 8px;
    background-color: #ff4444;
    /* or any color you prefer */
    color: white;
    border-radius: 50%;
    padding: 1px 1px;
    font-size: 12px;
    min-width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 2px solid white;
    /* Optional: adds a white border around the badge */
}

.pending-invites-badge:hover {
    cursor: pointer;
}